<template>
<div class="sonContent">
  <!--数据表格-->
  <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%;margin: 15px 0;"
      border
      height="calc(100vh - 328px)"
      :stripe="true">
    <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
    <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
    <el-table-column prop="createBy" label="提交人" align="center" width="80" show-overflow-tooltip></el-table-column>
    <el-table-column prop="createTime" label="提交时间" align="center" width="150" show-overflow-tooltip></el-table-column>
    <el-table-column label="操作" align="left" show-overflow-tooltip>
      <template slot-scope="{row}">
        <el-button v-if="hanleBtn==1" type="success"  size="small" @click="makeTrue(row,3)">确认收到</el-button>
        <el-button v-if="hanleBtn==2" type="success"  size="small" @click="turnMenu(row,4)">审核转交</el-button>
        <el-button v-if="hanleBtn==3" type="success"  size="small" @click="saveMenu(row,5)">存档</el-button>
        <el-button v-if="hanleBtn==4" type="success"  size="small" @click="dangnasaveMenu(row,6)">存档</el-button>
        <el-button v-if="hanleBtn==5" type="success"  size="small" @click="projectsaveMenu(row,7)">存档</el-button>
        <el-button v-if="hanleBtn==6" type="success"  size="small" @click="save(row)">存档</el-button>
      </template>
    </el-table-column>
  </el-table>
  <div class="pageBox">
    <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
  <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      @close="closeFrom('form')">
    <el-form ref="form" :model="form" label-width="80px" :rules="rule">
      <el-form-item label="内部编号" prop="archiveNumber">
        <el-input v-model="form.archiveNumber" ></el-input>
      </el-form-item>
      <el-form-item label="合同附件" prop="pcBackupsUrl">
            <span slot="label">
              <span style="color:red;margin-left:4px">*</span>
            合同附件
            </span>
        <el-upload
            ref="upload"
            action="#"
            :auto-upload="true"
            :http-request="repairBeforUploadFun"
            list-type="fileList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-upload="beforUploadImg"
            :on-change="beforHFhandleChangeImg"
            :file-list="FilefileList"
            accept=".pdf"
            :limit="1"
            :on-exceed="msgLimit"
        >
          <el-button size="small" type="text">上传PDF附件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="sumit('form')">确 定</el-button>
  </span>
  </el-dialog>
  <conPtf ref="ptfshow"></conPtf>
</div>
</template>

<script>
import btnHandle from "./btnHandle";
import {uploadImg} from "../../../RequestPort/apply/apply";
import conPtf from  "../../contractApply/conPtf"
import {pcAllList, updateType} from "../../../RequestPort/menu/menu";
export default {
  name: "dataTabel",
  components:{conPtf},
  props:{
    hanleBtn:{
      type:[Number,String],
      default:null
    }
  },
  mixins:[btnHandle],
  data(){
    return{
      dialogVisible:false,
      searchBox:{
        current:1,
        size: 100
      },
      FileFormData: new FormData(),
      FilefileList:[],
      form:{
        archiveNumber:'',
        pcBackupsUrl:'',
        type:8,
        completionId:''
      },
      rule:{
        archiveNumber: [
          { required: true, message: '请输入内部编号', trigger: 'blur' }
        ],
      },
      total:null,
      tableData:[]
    }
  },
  mounted() {
    this.searchBox.keyClass = this.hanleBtn
    this.loadList()
  },
  methods:{
    save(row){
      this.form.completionId = row.completionId
      this.dialogVisible = true
    },
    loadList(){
      pcAllList(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    sumit(form){
      this.$refs[form].validate((valid) => {
            if (!valid) return false
        updateType(this.form).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            this.loadList()
            this.dialogVisible = false
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    closeFrom(form){
      this.$refs[form].clearValidate();
      this.FilefileList = [];
      this.form.pcBackupsUrl ='';
      this.form.archiveNumber ='';
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList()
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList()
    },
    repairBeforUploadFun(e){
      this.FileFormData.append('file',e.file)
      uploadImg(this.FileFormData).then((res)=> {
        if(res.code==200){
          this.form.pcBackupsUrl=res.data.url
        }
        this.FileFormData.delete('file')
      })
    },
    handlePictureCardPreview(file) {
      this.$refs.ptfshow.previewPDF(file.url);
    },
    handleRemove(file) {
      this.FilefileList = [];
      this.form.pcBackupsUrl ='';
    },
    beforUploadImg(file){
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ['pdf'];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是pdf");
        return false;
      }
      const size = file.size / 1024 / 1024
      if (size > 100) {
        this.$message.error("大小必须小于100M");
        return false;
      }
    },
    beforHFhandleChangeImg(file, fileList) {
      this.FilefileList = fileList
    },
    msgLimit(file,fileList){
      this.$message.error("只能上传一个pdf");
    },
  }
}
</script>

<style scoped>

</style>