<template>
  <div class="sonContent">
    <div class="SearchBar" style="display: flex">
      <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
      <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
      <span style="margin: 6px 10px 0 20px " v-if="hanleBtn!=1">存档时间:</span>
      <el-select v-if="hanleBtn ==1" v-model="searchBox.timeType" style="width:100px;margin:0 10px" placeholder="请选择" size="small">
        <el-option
            v-for="item in dataList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker
          size="small"
          v-model="time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="选择开始日期时间"
          end-placeholder="选择结束日期时间">
      </el-date-picker>
      <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
      <el-button type="primary" class="SearchBtn" size="small" @click="resetData">导出</el-button>
    </div>
    <!--数据表格-->
    <el-table
        ref="tableData"
        :data="tableData"
        style="width: 100%;margin: 15px 0;"
        border
        height="calc(100vh - 380px)"
        :stripe="true">
      <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
      <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="constructionTeam" label="第三方施工队" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createBy" label="提交人" align="center" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="createTime" label="提交时间" width="150" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn!=1"  prop="internalCoding" label="内部编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==5"  label="备份文档" align="center" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <a :href="row.pcBackupsUrl" download="" target="_back">下载</a>
        </template>
      </el-table-column>
      <el-table-column v-if="hanleBtn!=1"  prop="archivePeople" label="存档人" align="center" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn!=1"  prop="archivingTime" label="存档时间"  width="150" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcObtainBy" label="确认人" align="center" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcObtainTime" label="确认时间"  width="150" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckBy" label="审核人" align="center" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column v-if="hanleBtn==1" prop="pcCheckTime" label="审核时间"  width="150" align="center" show-overflow-tooltip></el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
          :current-page="searchBox.current"
          :background="true"
          :page-sizes="[30, 50, 100]"
          :page-size="searchBox.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  archiveRecords,
  beCompletedList,
  pcProcessingRecord,
  pcProcessingRecordExcel,
  archiveRecordsExcel,
  beCompletedListExcel
} from "../../../RequestPort/menu/menu";
import { exportMethod } from '../../../common/js/exportExcel'
export default {
  name: "dataTabel",
  props:{
    hanleBtn:{
      type:[Number,String],
      default:null
    }
  },
  data(){
    return{
      searchBox:{
        current:1,
        size: 100,
        timeType:1
      },
      time:[],
      total:null,
      tableData:[],
      dataList:[
        {label:'审核时间',value:1},
        {label:'确认时间',value:2},
      ]
    }
  },
  mounted() {
    if(this.time&&this.time!=null){
      this.searchBox.startTime = this.time[0]
      this.searchBox.endTime = this.time[1]
    }else{
      this.searchBox.startTime = ''
      this.searchBox.endTime = ''
    }
    if(this.hanleBtn==1){//数据员处理记录
      pcProcessingRecord(this.searchBox).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    }else{
      if(this.hanleBtn==2){ //1运营存档记录
        this.searchBox.type = 1
        this.loadList(this.searchBox)
      }else if(this.hanleBtn==3){//2档案存档记录
        this.searchBox.type = 2
        this.loadList(this.searchBox)
      }else if(this.hanleBtn==4){//3工程存档记录
        this.searchBox.type = 3
        this.loadList(this.searchBox)
      }else if(this.hanleBtn==5){
        beCompletedList(this.searchBox).then(res=>{
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }

    }

  },
  methods:{
    loadList(obj){
      archiveRecords(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      this.loadList(this.searchBox)
    },
    handleCurrentChange(val){
      this.searchBox.current = val
      this.loadList(this.searchBox)
    },
    search(){
      if(this.time&&this.time!=null){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }else{
        this.searchBox.startTime = ''
        this.searchBox.endTime = ''
      }
      if(this.hanleBtn==1){//数据员处理记录
        pcProcessingRecord(this.searchBox).then(res=>{
          this.tableData = res.data.records
          this.total = res.data.total
        })
      }else{
        if(this.hanleBtn==2){ //1运营存档记录
          this.searchBox.type = 1
          this.loadList(this.searchBox)
        }else if(this.hanleBtn==3){//2档案存档记录
          this.searchBox.type = 2
          this.loadList(this.searchBox)
        }else if(this.hanleBtn==4){//3工程存档记录
          this.searchBox.type = 3
          this.loadList(this.searchBox)
        }else if(this.hanleBtn==5){
          beCompletedList(this.searchBox).then(res=>{
            this.tableData = res.data.records
            this.total = res.data.total
          })
        }

      }
    },
    resetData(){
      if(this.time.length>0){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }
      if(this.hanleBtn==1){//数据员导出
        pcProcessingRecordExcel(this.searchBox).then(res=>{
            exportMethod(res, '数据员处理记录列表')
        })
      }else if(this.hanleBtn==2){//运营存档daochu
        this.searchBox.type=1
        archiveRecordsExcel(this.searchBox).then(res=>{
          exportMethod(res, '运营存档记录列表')
        })
      }else if(this.hanleBtn==3){//2档案存档daochu
        this.searchBox.type=2
        archiveRecordsExcel(this.searchBox).then(res=>{
          exportMethod(res, '档案存档记录列表')
        })
      }else if(this.hanleBtn==4){//工程存档daochu
        this.searchBox.type=3
        archiveRecordsExcel(this.searchBox).then(res=>{
          exportMethod(res, '工程存档记录列表')
        })
      }else if(this.hanleBtn==5){//竣工资料档daochu
        beCompletedListExcel(this.searchBox).then(res=>{
          exportMethod(res, '工程存档记录列表')
        })
      }
    },
  }
}
</script>

<style scoped>
.SearchBar {
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
}
</style>