<template>
  <div class="content">
    <div class="contentbox">
      <el-tabs v-model="activeName">
        <el-tab-pane label="待确认" name="0">
          <DataTabel v-if="activeName==0" :hanleBtn="1"></DataTabel>
        </el-tab-pane>
        <el-tab-pane label="待审核" name="1">
          <DataTabel v-if="activeName==1" :hanleBtn="2"></DataTabel>
        </el-tab-pane>
        <el-tab-pane label="处理记录" name="2">
          <HandleRecord v-if="activeName==2" :hanleBtn="1"></HandleRecord>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DataTabel from './components/dataTabel'
import HandleRecord from './components/handleRecord'
export default {
  components:{DataTabel,HandleRecord},
  name: "dataMember",
  data(){
    return{
      activeName:0
    }
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
</style>