import { render, staticRenderFns } from "./dataMember.vue?vue&type=template&id=0f2b6f87&scoped=true"
import script from "./dataMember.vue?vue&type=script&lang=js"
export * from "./dataMember.vue?vue&type=script&lang=js"
import style0 from "./dataMember.vue?vue&type=style&index=0&id=0f2b6f87&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2b6f87",
  null
  
)

export default component.exports