import {updateType} from "../../../RequestPort/menu/menu";

export default {
    data(){
        return{

        }
    },
    methods:{
        //确认收到
        makeTrue(row,type){
            this.$confirm(`确认【${row.projectName}】的竣工资料已收到？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                updateType({completionId:row.completionId,type:type}).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.loadList()
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //审核转交
        turnMenu(row,type){
            this.$confirm(`确认【${row.projectName}】的竣工资料审核无问题，并转交相关部门存档？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                updateType({completionId:row.completionId,type:type}).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.loadList()
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //运营存档
        saveMenu(row,type){
            this.$prompt('内部编号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator:(value)=>{
                    if(value){
                        return true
                    }else {
                        return false
                    }
                },
                inputErrorMessage: '内部编号不能为空'
            }).then(({ value }) => {
                // 成功的操作
                updateType({completionId:row.completionId,type:type,archiveNumber:value}).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.loadList()
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        }, //档案存档
        dangnasaveMenu(row,type){
            this.$prompt('内部编号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator:(value)=>{
                    if(value){
                        return true
                    }else {
                        return false
                    }
                },
                inputErrorMessage: '内部编号不能为空'
            }).then(({ value }) => {
                // 成功的操作
                updateType({completionId:row.completionId,type:type,archiveNumber:value}).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.loadList()
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        }, //档案存档
        projectsaveMenu(row,type){
            this.$prompt('内部编号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator:(value)=>{
                    if(value){
                        return true
                    }else {
                        return false
                    }
                },
                inputErrorMessage: '内部编号不能为空'
            }).then(({ value }) => {
                // 成功的操作
                updateType({completionId:row.completionId,type:type,archiveNumber:value}).then(res=>{
                    if(res.code===200){
                        this.$message.success(res.msg)
                        this.loadList()
                    }else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
    }
}