/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-04-22 08:11:43
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-01 10:15:23
 * @FilePath: \linqingpc\src\RequestPort\apply\apply.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '@/common/js/request'

export function apply_page(data){
    const apply_page = axios({
        url: '/apply/page',
        method: 'get',
        params:data
    })
    return apply_page
}
export function applyStatus(){
    const list = [
        {value:1,label:'已登记'},
        {value:2,label:'已送审'},
        {value:3,label:'已审核'},
        {value:4,label:'已驳回'},
    ]
    return list;
}
export function apply_findById(data){
    const apply_findById = axios({
        url: '/apply/findById',
        method: 'get',
        params:data
    })
    return apply_findById
}
export function apply_censorship(data){
    const apply_censorship = axios({
        url: '/apply/censorship',
        method: 'get',
        params:data
    })
    return apply_censorship
}
export function constructionTeam_getTeamDic(data){
    const constructionTeam_getTeamDic = axios({
        url: '/constructionTeam/getTeamDic',
        method: 'get',
        params:data
    })
    return constructionTeam_getTeamDic
}
export function uploadImg(data){ //不带水印
    const uploadImg = axios({
        url: '/aliYun/uploadGasPatrolImgToAliYunOSS',
        method: 'post',
        data
    })
    return uploadImg
}
export function uploadImg1(data){ //带水印
    const uploadImg = axios({
        url: '/aliYun/uploadMarkImg',
        method: 'post',
        data
    })
    return uploadImg
}
export function apply_save(data){
    const apply_save = axios({
        url: '/apply/save',
        method: 'post',
        data:data
    })
    return apply_save
}
export function apply_update(data){
    const apply_update = axios({
        url: '/apply/update',
        method: 'post',
        data:data
    })
    return apply_update
}