import axios from '@/common/js/request'
//列表
export function pcAllList(params){
    const pcAllList = axios({
        url: '/completionData/pcAllList',
        method: 'get',
        params
    })
    return pcAllList
}
export function pcProcessingRecord(params){
    const pcProcessingRecord = axios({
        url: '/completionData/pcProcessingRecord',
        method: 'get',
        params
    })
    return pcProcessingRecord
}
export function archiveRecords(params){
    const archiveRecords = axios({
        url: '/completionData/archiveRecords',
        method: 'get',
        params
    })
    return archiveRecords
}
export function beRegisteredList(params){
    const beRegisteredList = axios({
        url: '/completionTemplate/beRegisteredList',
        method: 'get',
        params
    })
    return beRegisteredList
}
export function deleteRecord(params){
    const deleteRecord = axios({
        url: '/completionTemplate/deleteRecord',
        method: 'get',
        params
    })
    return deleteRecord
}
export function becheckList(params){
    const becheckList = axios({
        url: '/completionTemplate/becheckList',
        method: 'get',
        params
    })
    return becheckList
}
export function registerList(params){
    const registerList = axios({
        url: '/audit/registerList',
        method: 'get',
        params
    })
    return registerList
}
export function beCompletedList(params){
    const beCompletedList = axios({
        url: '/completionData/beCompletedList',
        method: 'get',
        params
    })
    return beCompletedList
}
export function viewMoreList(params){
    const viewMoreList = axios({
        url: '/completionTemplate/viewMoreList',
        method: 'get',
        params
    })
    return viewMoreList
}
export function updateType(data){
    const updateType = axios({
        url: '/completionData/updateType',
        method: 'post',
        data
    })
    return updateType
}
export function verdictCheck(data){
    const verdictCheck = axios({
        url: '/completionTemplate/verdictCheck',
        method: 'post',
        data
    })
    return verdictCheck
}
export function InsertBecheck(data){
    const InsertBecheck = axios({
        url: '/completionTemplate/InsertBecheck',
        method: 'post',
        data
    })
    return InsertBecheck
}
export function operationData(data){
    const operationData = axios({
        url: '/audit/operationData',
        method: 'post',
        data
    })
    return operationData
}
export function insertRegistrationData(data){
    const insertRegistrationData = axios({
        url: '/completionTemplate/insertRegistrationData',
        method: 'post',
        data
    })
    return insertRegistrationData
}
export function pcProcessingRecordExcel(params) {
    const pcProcessingRecordExcel = axios({
        url: '/completionData/pcProcessingRecordExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return pcProcessingRecordExcel
}
export function archiveRecordsExcel(params) {
    const archiveRecordsExcel = axios({
        url: '/completionData/archiveRecordsExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return archiveRecordsExcel
}
export function beCompletedListExcel(params) {
    const beCompletedListExcel = axios({
        url: '/completionData/beCompletedListExcel',
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return beCompletedListExcel
}